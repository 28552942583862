import React, {FC, FormEvent, useCallback, useState} from 'react';

// Styles
import styled from 'styled-components';

// Messages
import { FormattedMessage } from 'react-intl';
import generalMessages from '../../generalMessages';
import H2 from '../../../components/Headings/H2';
import FormInput from '../../../components/Inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import { loaderContactUsSelector } from '../selectors';
import Validator from 'validatorjs';
import ContactUsValidationRules from './ContactUsValidationRules';
import ContactUsValidationMessages from './ContactUsValidationMessages';
import { submitContactUsForm } from '../actions';
import FormGroup from '../../../components/Inputs/FormGroup';
import ErrorMessage from '../../../components/Inputs/ErrorMessage';
import Textarea from '../../../components/Inputs/Textarea';
import { optionsSubject } from '../../../config/selectOptions';
import Select from '../../../components/Inputs/Select';
import intl from '../../../intl';
import Button from '../../../components/ButtonsStyle';
import { LoadOverlaySticky } from '../../../components/Animations';

const ContactFormFooter: FC = () => {
  
  const dispatch = useDispatch(); 
  
  const [subject, setSubject] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
 
  const [emailError, setEmailError] = useState<boolean | string>('');
  const [subjectError, setSubjectError] = useState<boolean | string>('');
  const [messageError, setMessageError] = useState<boolean | string>('');
  

  const loader = useSelector(loaderContactUsSelector);
  
  const submit = useCallback((e:FormEvent)=>{
    e.preventDefault();
       const data = {
         email,
         subject,
         message
       }; 
      
      const validation = new Validator(data, ContactUsValidationRules, ContactUsValidationMessages);  
     
      validation.fails(() => {
        setEmailError(validation.errors.first('email'));
        setSubjectError(validation.errors.first('subject'));
        setMessageError(validation.errors.first('message'));
      });
      validation.passes(() => {
        setEmailError('');
        setSubjectError('');
        setMessageError('');
        dispatch(submitContactUsForm(data));
        setSubject('');
        setEmail('');
        setMessage('');
      });
      

  },[email, subject ,message, dispatch])
  
  return (

    loader ?  <LoadOverlaySticky /> :
    <FooterContent>
      <svg><use xlinkHref={`#lightning`} /></svg>
      <form onSubmit={submit}>
        <H2><FormattedMessage {...generalMessages.ContactUs} /></H2>
        <FormGroup>
          <FormInput
            type="email"
            inputPlaceholder={intl.formatMessage(generalMessages.Email)}
            defaultValue={email}
            onInputChange={setEmail}
            required={true}
          />
          {emailError ? <ErrorMessage>{emailError}</ErrorMessage> : null}
        </FormGroup>
        <FormGroup>
          <Select
            options={optionsSubject}
            onSelectChange={setSubject}
            value={subject}
            defaultOption={''}
            defaultOptionLabel={intl.formatMessage(generalMessages.UserTitle)}
            defaultOptionDisabled={true}
            defaultOptionHidden={true}
            required={true}
          />
          {subjectError ? <ErrorMessage>{subjectError}</ErrorMessage> : null}
        </FormGroup>
        <FormGroup>
          <Textarea
            placeholder={intl.formatMessage(generalMessages.Description)}
            defaultValue={message}
            onChange={setMessage}
            rows={5}
            required={true}
          />
          {messageError ? <ErrorMessage>{messageError}</ErrorMessage> : null}
        </FormGroup>
        <FormGroup>
          <Button type="submit"><FormattedMessage {...generalMessages.SendMessageForm} /></Button>
        </FormGroup>
      </form>    
    </FooterContent>
  )
}

const FooterContent = styled.div`
  margin: auto 0;
  form {
    margin-top: 70px;
  }
`;

export default ContactFormFooter;

