import React, {FC} from 'react';
import styled from 'styled-components';
import colors from '../../../config/colors';

interface OptionType { label?: string; value?: string };

interface SelectProps {
  options: OptionType[];
  onSelectChange: (value: any) => void;
  value?: any;
  name?: string;
  defaultOption?: string;
  defaultOptionLabel?: string;
  defaultOptionDisabled?: boolean;
  defaultOptionHidden?: boolean;
  defaultOptionText?: string;
  disabled?: boolean;
  icon?: string;
  theme?: string;
  required?: boolean;
  [x:string]: any;
}

const Select: FC<SelectProps> = ({onSelectChange, options, value, name, defaultOption, defaultOptionLabel, defaultOptionDisabled, defaultOptionHidden, defaultOptionText, disabled, icon, theme, required, ...props}) => {

  const setValue = (selectedOption: any) => {
    onSelectChange(selectedOption.target.value);
  }

  const reset = () => {
    onSelectChange('');
  }

  return (
    options ?
      <SelectWrapper className={`${value ? 'hasValue' : ''} ${icon ? '' : 'selectIcon'} ${theme === 'white' ? 'whiteBg' : ''}`}>
        {icon ?
          <svg className="icon"><use xlinkHref={`#${icon}`} /></svg>
        : null}
        <select
          name={name}
          onChange={(option) => setValue(option as OptionType)}
          value={value}
          disabled={disabled}
          {...props}
        >
          {defaultOptionLabel ?
            <option value={defaultOption} disabled={defaultOptionDisabled} hidden={defaultOptionHidden}>{defaultOptionText ?? null}</option>
          : null}
          {options.map(function(option: any, i: number){
            return <option value={option.value} key={i} id="test">{option.label}</option>
            })
          }
        </select>
        <p className={`${value ? 'hasValue' : ""} ${icon ? "" : "smaller-left"}`}>{defaultOptionLabel} {required ? <sup>*</sup> : null}</p>

        {!disabled ? <svg className="select-arrow"><use xlinkHref={`#select-arrow-icon`} /></svg> : null}
        <span onClick={reset}>
          <svg><use xlinkHref={`#close`} /></svg>
        </span>
      </SelectWrapper>
    : null
  )
}

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .icon {
    z-index: 1;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    stroke: ${colors.BLACK};

    @media (max-width: 576px) {
      width: 14px;
      height: 14px;
    }
  }

  p {
    position: absolute;
    max-width: 100%;
    font-family: "Gilroy-Medium";
    left: 40px;
    right: 40px;
    top: 25px;
    transform: translateY(-50%);
    font-size: 16px;
    color: ${colors.GRAY_TEXT_MEDLIGHT};
    z-index: 1;
    pointer-events: none;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 576px) {
      right: 35px;
      left: 35px;
    }

    sup {
      color: ${colors.PRIMARY_RED};
      font-size: 16px;
      position: relative;
      right: -3px;
      bottom: -5px;
      line-height: 0;
    }
  }

  .smaller-left {
    left: 14px;
  }

  .icon + .hasValue + select {
    padding-left: 40px;
  }

  select {
    font-family: "Gilroy-Medium";
    font-size: 16px;
    position: relative;
    appearance: none;
    height: 50px;
    width: 100%;
    max-width: 100%;
    background: ${colors.GRAY_EXTRA_LIGHT};
    color: ${colors.BLACK};
    border: 0;
    border-radius: 3px;
    padding: 14px 40px;
    transition: all 200ms ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 576px) {
      padding: 14px 35px;
    }

    option[disabled],
    option:disabled {
      color: ${colors.TEXT_GRAYLIGHT};
    }

    &:focus {
      outline: none;
    }
  }

  img, svg {
    position: absolute;
    right: 14px;
    top: 25px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .select-arrow {
    stroke: ${colors.BLACK};
  }

  span {
    display: none;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;

    svg {
      display: block;
      width: 10px;
      height: 10px;
      fill: ${colors.BLACK};
    }
  }

  &.hasValue {

    select {

      option {
        background: rgba(255,255,255,0.3) !important;
      }
    }

    p {
      z-index: -1;
      visibility: hidden;
    }
  }

  &.selectIcon {

    select {
      padding: 14px 40px 14px 14px;
    }
  }

  &.whiteBg {

    select {
      background: ${colors.WHITE};
    }
  }
`;

export default Select;
