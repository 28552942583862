import React, { FC, useCallback} from 'react';
import styled from 'styled-components';
import colors from '../../../config/colors';
import RteTextarea from './Loadable';
import StandardTextarea from './textarea';

interface Props {
  theme?: string;
  placeholder?: any,
  rows?: number,
  defaultValue?: string,
  rte?: boolean,
  onChange: (content: any) => void,
  customToolbar? : any | null,
  maxChars?: number,
  maxCharsMessage?: string,
  required?: boolean,
  [x:string]: any;
}

const Textarea: FC<Props> = ({placeholder, rows, onChange, defaultValue, rte, theme, maxCharsMessage, maxChars, required, customToolbar}) => {

  const onTextAreaChange = useCallback((content: any)=>{
    onChange(content);
  }, [onChange])

  return (

    <Wrapper className={theme === 'white' ? 'whiteBg' : ''}>
      {rte ?
        <RteTextarea
          placeholder={placeholder}
          onChange={onTextAreaChange}
          defaultValue={defaultValue}
          customToolbar={customToolbar}
        /> 
        :
        <StandardTextarea
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          rows={rows}
          maxCharsMessage={maxCharsMessage}
          maxChars={maxChars}
          required={required}
        />
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  .quill {
    display: block;
    width: 100%;
    overflow: auto;
    background-color: ${colors.GRAY_EXTRA_LIGHT};
    border-radius: 3px;
    font-family: "Gilroy-Medium";
    font-size: 16px;
    color: ${colors.BLACK};
    transition: all 200ms ease-in-out;

    .ql-container,
    .ql-editor {
      min-height: 140px;
      font-family: "Gilroy-Medium";
      color: ${colors.BLACK};

      p, li {
        font-family: "Gilroy-Medium";
        font-size: 16px;
        color: ${colors.BLACK};
      }
    }

    .ql-editor iframe {
        pointer-events: none;
    }

    .ql-toolbar.ql-snow .ql-formats {

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #c4c4c4;
  }

  .ql-container.ql-snow {
    border: none;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0 1px #9B9B9B29;
  }

  .ql-editor.ql-blank::before {
    font-family: "Gilroy-Medium";
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "Ubaci link";
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: "Sačuvaj";
  }
  .ql-tooltip.ql-editing.ql-flip, .ql-snow .ql-tooltip {
    left: 35% !important;
    top: 35% !important;
    box-shadow: 1px 1px 1px #000;
  }

  &::-webkit-input-placeholder {
    font-size: 16px;
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  &:-ms-input-placeholder {
    font-size: 16px;
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  &::placeholder {
    font-size: 16px;
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  &.whiteBg {

    .quill, textarea {
      background-color: ${colors.WHITE};
    }
  }
`;

export default Textarea;
