import React, { FC, useCallback, memo, useState } from 'react';

import styled from 'styled-components';
import colors from '../../../config/colors';
import ErrorMessage from '../ErrorMessage';


interface Props {
  theme?: string;
  placeholder?: string,
  rows?: number,
  defaultValue?: string,
  onChange: (content: any) => void,
  maxChars?: number,
  maxCharsMessage?: string,
  required?: boolean,
}

const StandardTextarea: FC<Props> = ({placeholder, rows, onChange, maxChars, defaultValue, theme, maxCharsMessage, required, ...props}) => {

  const [maxCharsLimit, setMaxCharsLimit] = useState<boolean>(false)

  const onTextAreaChange = useCallback((e) => {
    const value = e.target.value;
    if(maxChars && value && (value.length > maxChars)) {  
      setMaxCharsLimit(true);
    }
    onChange(e.target.value);
  }, [maxChars, onChange])

  return (
    <TextareaWrapper>
      <TextareaStandard className={theme === 'white' ? 'whiteBg' : ''}
        placeholder={''}
        onChange={onTextAreaChange}
        value={defaultValue}
        rows={rows}
        {...props}
      >
      </TextareaStandard>
      {!defaultValue ?
        <Placeholder>
          {placeholder} {required ? <sup>*</sup> : null}
        </Placeholder>
      : null}
      {maxCharsLimit ? <ErrorMessageChar>{maxCharsMessage}</ErrorMessageChar> : null}
    </TextareaWrapper>
  )
};

const TextareaWrapper = styled.div`
  position: relative;
  margin: 0 0 10px;
`;

const TextareaStandard = styled.textarea`
  display: block;
  width: 100%;
  padding: 14px;
  resize: none;
  background-color: ${colors.GRAY_EXTRA_LIGHT};
  border-radius: 3px;
  border: 0;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  color: ${colors.BLACK};
  transition: all 200ms ease-in-out;
  -webkit-appearance: none;

  &::-webkit-input-placeholder {
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  &:-ms-input-placeholder {
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  &::placeholder {
    color: ${colors.GRAY_TEXT_MEDLIGHT};
  }

  &:focus {
    outline: none;
  }

  &.whiteBg {

    textarea {
      background-color: ${colors.WHITE};
    }
  }
`;

const Placeholder = styled.span`
  position: absolute;
  top: 25px;
  transform: translateY(-50%);
  padding: 14px;
  font-size: 16px !important;
  font-family: "Gilroy-Medium";
  color: ${colors.GRAY_TEXT_MEDLIGHT} !important;
  pointer-events: none;
  width: 100%;
  z-index: 10;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  sup {
    color: ${colors.PRIMARY_RED};
    font-size: 16px;
    position: relative;
    right: -3px;
    bottom: -5px;
    line-height: 0;
  }
`;

const ErrorMessageChar = styled(ErrorMessage)`
  margin-top: 15px;
`;

export default memo(StandardTextarea);
